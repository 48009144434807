@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "variables.scss";

.mdContainer {
  width: 100%;
  max-width: 1156px;
  margin: 0 auto;
  padding: 0 20px;

  @include mqMaxWidth(480px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.lgContainer {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @include mqMaxWidth(480px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.narrowContainer {
  @include mqMaxWidth(1024px) {
    padding: 0;
  }
}

.sectionTitle {
  color: $titleColor;
  font-family: $baseFontFamily;
  font-weight: bold;
  font-size: 36px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 0 0 28px;

  @include mqMaxWidth(768px) {
    font-size: 30px;
    line-height: 33px;
  }
}

.sectionDescription {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: $baseTextColor;
  text-align: center;
  margin-bottom: 45px;

  a {
    color: $baseTextColor;
  }

  @include mqMaxWidth(640px) {
    font-size: 15px;
    line-height: 18px;
  }
}

.secondaryBtn {
  background-color: $secondaryColor;

  &:hover,
  &:active {
    background-color: $secondaryColorHover;
  }
}

.actionButton {
  height: 56px;
  padding: 0 30px;

  font-size: 17px;
  letter-spacing: 0.01em;

  @include mqMaxWidth(640px) {
    width: 100%;
    height: 36px;
    margin: 0;

    font-size: 14px;
  }
}

