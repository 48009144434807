@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/components/Clearfix/clearfix.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/dataroom/ui/components/Landing/variables.scss";

$menuLinkColor: #616161;
$menuLinkHoverColor: #2d2d2d;
$activeMenuLinkColor: #2d2d2d;
$headerHeight: 66px;

.headerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #494949;
  opacity: 0.5;
  z-index: 5;
}

.headerWrp {
  background: #fff;
  height: 100%;
  @include clearfix;
}

.isHeaderHidden {
  opacity: 0;
  visibility: hidden;
}

.headerContainer {
  max-width: 1440px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
  height: 66px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.headerActions {
  line-height: 66px;

  .menuBtn {
    margin-left: 15px;
    margin-right: 0;

    @include mqMaxWidth(568px) {
      margin-left: 0;
    }
  }
}

.headerUserActions {
  display: inline-block;

  @include mqMaxWidth(568px) {
    .menuLink,
    .headerBtn {
      display: none;
    }

    .menuLoginButton {
      display: inline-block;
    }
  }
}

.mobileHeaderUserActions {
  display: none;

  .headerBtn {
    display: inline-block;
    width: 100%;
    border-top: 1px solid #ececec;
  }

  .menuBtn {
    width: 100%;
  }

  @include mqMaxWidth(568px) {
    display: block;
  }
}

.headerMenu {
  display: block;

  @include mqMaxWidth(870px) {
    .menuLink,
    .headerBtn {
      display: none;
    }
  }
}

.mobileHeaderMenu {
  display: none;

  @include mqMaxWidth(870px) {
    display: block;
  }
}

.mobileHeaderMenu,
.mobileHeaderUserActions {
  padding: 0 20px;

  .menuLink,
  .headerBtn {
    padding: 21px 0 22px;
  }
}

.burger {
  cursor: pointer;
  display: none;
  vertical-align: top;
  margin-top: 24px;
  margin-right: 33px;

  &.tenantDealroadshow {
    svg {
      fill: #4a76b6;
    }
  }

  @include mqMaxWidth(870px) {
    display: inline-block;
  }

  @include mqMaxWidth(568px) {
    margin-right: 23px;
  }
}

.logo {
  display: inline-block;
  margin-top: 15px;

  @include mqMaxWidth(568px) {
    width: 152px;
    overflow: hidden;

    img {
      max-width: none;
      position: relative;
      left: -83px;
    }
  }
}

.menu {
  display: inline-block;
  margin-left: 15px;
  vertical-align: top;

  @include mqMaxWidth(870px) {
    display: none;
  }

  &.isMobileMenu {
    position: absolute;
    top: $headerHeight - 1;
    background: #fff;
    width: 100%;
    left: 0;
    margin: 0;
    display: block;
    z-index: -1;
    padding-top: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    .menuLink {
      border-top: 1px solid #ececec;
      width: 100%;
    }

    .menuLink:hover,
    .isMenuLinkActive {
      background: #f9f9f9;

      &:after {
        content: none;
      }
    }
  }
}

.menuLink {
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $menuLinkColor;
  padding: 23px 15px 24px;
  letter-spacing: 0.01em;

  &:hover {
    color: $menuLinkHoverColor;
  }
}

.headerMenu,
.headerUserActions {
  .menuLink {
    @include mqMaxWidth(870px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @include mqMaxWidth($screenXs) {
      &.tenantDealroadshow {
        padding: 23px 0;

        &.menuLoginButton {
          font-size: 0;
        }

        .menuLoginIcon {
          fill: $actionColor;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}

.isMenuLinkActive {
  font-weight: bold;
}

.menuLink:hover,
.isMenuLinkActive {
  color: $activeMenuLinkColor;

  &:hover {
    color: $activeMenuLinkColor;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 28px;
    background: $activeMenuLinkColor;
    height: 3px;
    transform: translate3d(-50%, 0, 0);
  }

  &.menuIconLink {
    &:after {
      margin-left: 12px;
    }
  }

  .menuLoginIcon {
    fill: $activeMenuLinkColor;
  }

  @include mqMaxWidth($screenXs) {
    &.tenantDealroadshow {
      .menuLoginIcon {
        fill: $actionColorHover;
      }

      &:after {
        display: none;
      }
    }
  }
}

.menuLoginIcon {
  width: 12px;
  fill: $baseTextColor;
  margin-right: 10px;
  top: 2px;
}

.header {
  width: 100%;
  position: relative;
  z-index: 6;
  top: 0;
  opacity: 1;
  visibility: visible;
  transition: opacity 200ms, visibility 200ms;

  /* Tenant specific */
  :global(.tenant-investorset) {
    .menu:not(.isMobileMenu) {
      @include mqMaxWidth(900px) {
        display: none;
      }
    }

    .burger {
      @include mqMaxWidth(900px) {
        display: inline-block;
      }
    }

    .logo {
      @include mqMaxWidth(620px) {
        width: 125px;
        overflow: hidden;

        img {
          max-width: none;
          position: relative;
          left: -83px;
        }
      }
    }
  }

  :global(.tenant-17g5) {
    @include mqMaxWidth(571px) {
      .headerUserActions {
        .menuLink,
        .headerBtn {
          display: none;
        }

        .menuLoginButton {
          display: inline-block;
        }
      }

      .logo {
        margin-top: 15px;
        overflow: hidden;

        img {
          position: relative;
          width: auto;
          left: -83px;
        }
      }

      .burger {
        margin-right: 23px;
      }
    }
  }

  :global(.tenant-dealroadshow) {
    @include mqMaxWidth(568px) {
      .logo {
        width: 168px;
      }
    }
  }

  :global(.tenant-verisend) {
    @include mqMaxWidth(568px) {
      .logo {
        margin-top: 15px;

        img {
          left: -79px;
          width: 174px;
        }
      }
    }
  }
}

.headerFixed {
  position: fixed;
  top: 0;
}
