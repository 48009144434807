@import "../base.scss";
@import "variables.scss";

.pageContainer {
  padding-top: 66px;
  display: block;
  width: 100%;
  background-color: $baseBackgroundColor;
  overflow: hidden;
}

.intro {
  position: relative;
  padding-top: 104px;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 1000px;
    top: 725px;
    left: 0;
    transform: skewY(-7deg);
    background: $tenant17g5Color;
  }

  @include mqMaxWidth($screenM) {
    padding-top: 50px;
  }

  @include mqMaxWidth($screenXs) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.description {
  max-width: 663px;
  margin: 0 auto 51px;

  span {
    font-weight: bold;
  }
}

.sectionTitle {
  > span {
    font-family: $baseFontFamily;
  }
}

.titlePattern {
  text-align: center;
  margin-bottom: 15px;

  @include mqMaxWidth($screenM) {
    display: none;
  }
}
