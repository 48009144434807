@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 66px);
  width: 100%;
  z-index: 0;
}

.backgroundImage {
  fill: $secondary2Color;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}

.title {
  color: $titleColor;
  font-family: $titleFontFamily;
  font-weight: bold;
  font-size: 36px;
  line-height: 39px;
  text-align: center;
  margin: 0;
}

.subTitle {
  color: $baseTextColor;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  margin: 0;
}

.backButton {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
